<template>
	<div>
		<div class="table-responsive">
			<table class="table b-table table-fixed table-bordered">
				<thead>
					<tr>
						<th>
						<div class="d-flex" style="gap: 10px;">
							<b-img
								class="filter-image"
								:class="filter.order_type == 'asc' ? 'rotate-180' : ''"
								:src="require('@/assets/images/icons/Icon-order-list-down.svg')"
								alt="Icon-order-list-down"
								role="button"
								@click="filter.order_type == 'asc' ? filter.order_type = 'desc' : filter.order_type = 'asc'"
							/>
							<span>No</span>
						</div>
						</th>
						<th>Title</th>
						<th>Category</th>
						<th>Sub Category</th>
						<th>Type</th>
						<th>Status</th>
						<th>User Email</th>
						<th>Actions</th>
					</tr>
				</thead>
				<tbody>
					<tr v-if="isLoadingComponent">
						<td
							colspan="12"
							class="text-center"
						>
						<b-spinner
							class="mb-1"
							variant="primary"
						/><br>
							Loading...
						</td>
					</tr>
					<tr
						v-for="item, index in inspirationList" :key="item.slug">
						<td>
							{{ (currentPageList - 1) * 10 + index + 1 }}
						</td>
						<td>
							<a :href="path + '/' + item.slug" target="_blank">
								<div v-if="item.is_flag">
									<div class="text-detail" v-html="item.title || '-'"></div>
									<span  class="badge badge-primary">
										<feather-icon
										icon="TagIcon"
									/>
									Pinned</span>
								</div>
								<div class="text-detail" v-else v-html="item.title || '-'"></div>
							</a>
						</td>
						<td>
							{{ item.category || '-' }}
						</td>
						<td>
							{{ item.sub_category || '-' }}
						</td>
						<td>
							<span
								class="badge badge-pill badge-success"
								v-if="item.content_type === 'article'"
							>
								Artikel
							</span>
							<span
								class="badge badge-pill badge-danger"
								v-if="item.content_type === 'video'"
							>
								Video
							</span>
							<span
								class="badge badge-pill badge-secondary"
								v-if="item.content_type === 'text_only'"
							>
								Status
							</span>
						</td>
						<td>
							{{ item.status || '-'}}
						</td>
						<td>
							{{ item.user_email || '-' }}
						</td>
						<td class="text-right">
							<b-dropdown
								variant="danger"
								size="sm"
							>
								<template #button-content>
									Action
								</template>

								<b-dropdown-item
									@click="draftItem(item.slug)"
								>
									<feather-icon
										icon="XIcon"
										class="mr-75"
									/>
										Draft
								</b-dropdown-item>

								<b-dropdown-item>
									<router-link :to="{ name: 'detail-inspiration', params: { slug: item.slug }}">
										<feather-icon
											icon="EditIcon"
											class="mr-75"
										/>
										Update Content
									</router-link>
								</b-dropdown-item>

								<b-dropdown-item
									v-if="item.is_flag"
									@click="pinItemContent(item.slug, true)"
								>
								<feather-icon
									icon="TagIcon"
									class="mr-75"
								/>
									Unpin Inspiration
								</b-dropdown-item>

								<b-dropdown-item
									v-else
									@click="pinItemContent(item.slug)"
								>
								<feather-icon
									icon="TagIcon"
									class="mr-75"
								/>
									Pin Inspiration
								</b-dropdown-item>
								
								<b-dropdown-item
									@click="deleteItem(item.slug)"
								>
								<feather-icon
									icon="TrashIcon"
									class="mr-75"
								/>
									Delete
								</b-dropdown-item>
							</b-dropdown>
						</td>
					</tr>
					<tr v-if="resultData.total == 0 && !isLoadingComponent">
						<td
							colspan="12"
							class="text-center"
						>
							Data is empty.
						</td>
					</tr>
				</tbody>
			</table>

			<div
				v-if="resultData.total > 0"
				class="m-1"
			>
				<div class="row">
					<div class="col mb-1">
						<small>Showing {{ resultData.from }} to {{ resultData.to }} from {{ resultData.total }}</small>
					</div>
					<div class="col">
						<pagination
							:data="resultData"
							:limit="4"
							align="right"
							@pagination-change-page="getData"
						/>
					</div>
				</div>
			</div>
		</div>

	</div>
</template>

<script>
import { 
	BTable,
	BSpinner,
	BBadge,
	BImg,
	BDropdown,
  	BDropdownItem,
} from 'bootstrap-vue'
import _ from 'lodash'

const components = {
	BTable,
	BSpinner,
	BBadge,
	BImg,
	BDropdown,
	BDropdownItem,
} 

const collectProps = {
	inspiration: {
		type: Array,
	},
	result: {
		type: Object,
	},
	isLoading: {
		type: Boolean,
	},
	currentPage: {
		type: Number,
	},
	getData: {
		type: Function,
	},
	deleteItem: {
		type: Function,
	},
	pinItemContent: {
		type: Function,
	},
	draftItem: {
		type: Function,
	},
}

const watchs = {
	isLoading(value) {
		this.isLoadingComponent = value
	},
	inspiration(value) {
		this.inspirationList = value
	},
	currentPage(value) {
		this.currentPageList = value
	},
	result(value) {
		this.resultData = value
	},
	filter: {
		handler(value) {
			this.$emit('filter', value)
		},
		deep: true
	}
}

const data = () => {
	return {
		isLoadingComponent: true,
		currentPageList: 1,
		resultData:{},
		inspirationList: [],
		filter: {
			order_type: 'desc'
		},
		path: process.env.URL_LANDING_PAGE
	}
}

const computed = {
	rows() {
		return this.inspirationList.length
	}
}

export default {
	components,
	props: collectProps,
	watch: watchs,
	data:  data,
	computed: computed
}
</script>

<style>
.text-detail {
	width: 350px;
}
.table tbody tr:not([class*=table-]) td {
	vertical-align: top;
}
</style>